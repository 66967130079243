import router from "@/router";
import Layout from "@/views/layout/Layout.vue";
import { getMenus, getRoles } from "@/api/common";
export default {
  state: {
    dynamicRouter: []
  },
  mutations: {
    SET_ROUTE(state, data) {
      state.dynamicRouter = data;
    }
  },
  actions: {
    getRouters({ commit }) {
      getMenus().then(res => {
        //重整路由数据
        const data = res.data.data.map(item => {
          item.component = Layout
          if(item['children'] && item['children'].length > 0) {
            item['children'] = item['children'].map((v) => {
              let component = () => import('@/views/' + v.component);
              return {
                path: v.path,
                component,
                meta: {
                  keepAlive: false,
                  ...v.meta
                }
              }
            })
          }
          return item
        })
        
        commit('SET_ROUTE', data)

        //添加动态路由
        data.forEach((item) => {
          router.addRoute(item)
        })
        
      })
    }
  },
  
}