<template>
  <div class="menu-tags">
    <el-tag
      v-for="tag in tagList"
      :key="tag.path"
      size="small"
      :closable="isAffix(tag) ? false : true"
      @click="handleTagClick(tag)"
      @close="handleClose(tag)">
      <router-link 
        class="tags-item" 
        :to="tag.path"
        @contextmenu.prevent.native="openMenu(tag,$event)">
        {{ tag.title }}
      </router-link>
    </el-tag>
    <ul class="menu-context" v-show="visible" :style="{top: top + 'px', left: left + 'px'}">
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">关闭当前</li>
      <li @click="closeOthersTags(selectedTag)">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">关闭全部</li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false, //标签右键卡片是否显示
        top: 0,  //标签右键卡片位置
        left: 0,
        tagList: [],
        selectedTag: {},
        isCurrent: false
      }
    },
    created() {
      this.initTags()
    },
    methods: {
      isAffix(tag) {
        return tag.affix
      },
      //计算标签
      initTags() {
        this.tagList = []
        this.visible = false
      },
      //关闭标签页
      handleClose(tag) {
        this.visible = false; //关闭标签右键卡片
      },
      handleTagClick(tag) {
        this.$router.push({path: tag.path})
      },
      // 打开右键卡片
      openMenu(tag, e) {
        this.left = e.clientX - 25
        this.top = e.clientY + 15
        this.visible = true
        this.selectedTag = tag
      },
      //关闭当前标签
      closeSelectedTag(tag) {
        this.handleClose(tag)
      },
      //关闭其他标签
      closeOthersTags(tag) {
        this.visible = false;
        this.$router.push(tag.path)
        this.$store.commit('colseOtherTag', tag)
      },
      //关闭所有标签
      closeAllTags() {
        this.initTags()
      }
    },
    //侦听器
    watch: {
      //监听路由变化 ，获取路由标签
      "$route.path"(val) {
        console.log(val);
        
        // this.tagList.push()
      },
      "orderTags.length"(newValue, oldValue) {
        if(newValue > oldValue && newValue > 0) {
          const element = document.querySelectorAll('.tags-item');
          element.forEach(item => {
            item.classList.remove('router-link-active');  //移除激活样式
          })
        } else {
          const element = document.querySelectorAll('.tags-item');
          element[element.length -1].classList.add('router-link-active');  //移除激活样式
        }
      }
    }

  }
</script>

<style lang="less" scoped>

</style>