import Vue from 'vue'
import Vuex from 'vuex'
import navs from './navs'
import system from './system'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navs,
    system
  }
})
