/**
 * @file 公共接口函数
 * @date 2024.11.25
*/

import request from '@/utils/request'

//菜单查询
export function getMenus(data) {
  return request({
    url: '/Menu/Get',
    method: 'post',
    data
  })
}

//角色Id查询
export function getRoles(data) {
  return request({
    url: '/Cmmn/QueryAllRole',
    method: 'post',
    data
  })
}