import router from "@/router";
export default {
  state: {
    showActionDialog: false,
    menuId: '',
  },
  mutations: {
    SET_MENU_ACTION_DIALOG(state, data) {
      state.showActionDialog = data;
    },
    SET_NENU_ID(state, value) {
      return state.menuId = value
    },
  },
  
}