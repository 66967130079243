import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import Login from '@/views/login/Login.vue';
import Layout from '@/views/layout/Layout.vue';
import local from '@/utils/local';

//重写路由的push，解决跳转当前导航的警告
const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location){
  return originPush.call(this,location).catch(err=>err)
} 

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login' // 直接重定向
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let tk = local.get('token')
  if(to.path !== '/login' && !tk) {
    next('/login')
  } else {
    store.dispatch('getRouters')
    next()
  }
})

export default router
