import { render, staticRenderFns } from "./RightHeader.vue?vue&type=template&id=3f202206&scoped=true"
import script from "./RightHeader.vue?vue&type=script&lang=js"
export * from "./RightHeader.vue?vue&type=script&lang=js"
import style0 from "./RightHeader.vue?vue&type=style&index=0&id=3f202206&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f202206",
  null
  
)

export default component.exports