/* 封装ajax工具函数 */
import axios from 'axios'
import local from '@/utils/local'
import router from '@/router/index' // 引入路由 自动找到index.js
import { Message } from 'element-ui'

export const baseUrl = 'https://www.80327777.com:8888';
// 设置统一的服务器地址
axios.defaults.baseURL = baseUrl

// 请求拦截器 interceptors  ( ajax发送出去之前 )
axios.interceptors.request.use(config => {
    // 统一带令牌
    let token = local.get('token') // 从本地取出令牌
    if (token) {  // Authorization
        config.headers.Authorization = token; // 所有的请求 都要携带令牌 才能正常
    }
    return config
}, err => {
    return Promise.reject(err) // 让Promise状态从进行中 -> 失败
}) 

// 响应拦截器 interceptors ( 接收到数据之前 )
axios.interceptors.response.use(response => {
    // token过期，重返登陆界面
    if(response.data.code === 1000) {
        Message({
            message:response.data.msg,
            type:'error',
        })
        router.push({path: '/login'})
    }
    if(response.data.code === 9999) {
        Message({
            message:response.data.msg,
            type:'error',
        }) 
        router.push({path: '/login'})
    }
    return response
}, (err) => {
    return Promise.reject(err) // 让Promise状态从进行中 -> 失败  
})

// 暴露出去
export default axios