<template>
  <div class="login-page">
    <div class="login-bg"></div>
    <div class="login-main">
      <div class="login-box">
        <h1 class="title">扫码登录</h1>
        <div class="qr">
          <img :src="url" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginMethod, checkCode } from '@/api/login';
import local from '@/utils/local';
export default {
  data() {
    return {
      url: '',  //二维码地址
      key: '',  //获取扫码状态时参数
      timer: null
    }
  },
  created() {
    this.getLoginCode()
    this.timer = setInterval(this.checkScanStatus, 3000)
  },
  methods: {
    getLoginCode() {
      loginMethod().then(res => {
        let { code, data } = res.data
        if(code == 0) {
          this.url = 'data:image/png;base64,' + data.qr
          this.key = data.key
        }
      })
    },
    checkScanStatus() {
      let params = {
        _: Date.now()
      }
      checkCode(params, this.key).then(res => {
        let { data, code, msg } = res.data;
        if(code == 0) {
          clearInterval(this.timer)
          local.set('token', data.key)
          local.set('userInfo', data)
          this.$router.push({path: '/sys-manage'})
        } else if(code == 2) {
          this.getLoginCode()
          this.$message({type: 'error', message: msg})
        } else {
          this.$message({type: 'error', message: msg})
        }
      })
      
    },
    getBeijingTimestamp() {
      // 创建一个新的Date对象表示当前时间
      const now = new Date();
      console.log(now);
      
 
      // 获取当前时区的偏移量，单位是分钟
      const localOffset = now.getTimezoneOffset();
 
      // 北京时间与UTC时间的时差是5小时30分钟，转换为分钟
      const beijingOffset = (5 * 60 + 30) * 60000;
 
      // 将当前时间转换为北京时间，并获取其时间戳
      const beijingTime = new Date(now.getTime() + localOffset * 60000 + beijingOffset);
      return beijingTime.getTime();
    }
  }
}
</script>

<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
}
.login-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("@/assets/imgs/bg.jpg") no-repeat;
  background-size: cover;
  /* 设置背景图片固定，不随滚动条滚动 */
  background-attachment: fixed;
  /* 应用模糊效果 */
  filter: blur(9px);
  /* 设置模糊效果应用的区域 */
  background-blend-mode: normal;
  /* 确保模糊效果应用在背景上 */
  z-index: -1;
}
.login-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .login-box {
    width: 350px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 0 20px #000000;
    .title {
      color: #000;
      font-size: 20px;
      text-align: center;
    }
    .qr {
      width: 300px;
      height: 300px;
      background-color: #fff;
      margin-left: 25px;
    }
  }
}
</style>