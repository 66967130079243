<template>
  <div class="layout-page">
    <left-nav></left-nav>
    <div class="right">
      <right-header></right-header>
      <div class="exit">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from './LeftNav.vue';
import RightHeader from './RightHeader.vue';
  export default {
    components: {
      LeftNav,
      RightHeader
    }
  }
</script>

<style lang="less" scoped>
.layout-page {
  display: flex;
  height: 100%;
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    .exit {
      flex: 1;
      padding: 16px;
      background-color: #F0F2F5;
    }
  }
}
</style>