/**
 * @files 登录接口
 * @date 2024.11.20
 */

import request from '@/utils/request'; // 引入封装好的axios

//获取登录方法
export function loginMethod(data) {
  return request({
    url: '/Auth/LoginMethod',
		method: 'post',
		data
  })
}

//获取扫码状态
export function checkCode(data, key) {
  return request({
    url: '/Auth/CheckQrCode',
		method: 'post',
		data,
    headers: {
      Key: key,
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}